<template>
  <div class="home">
    <div style="position:relative">
      <img class="landing_1" src="../assets/img_1.jpg">
      <button class=" btn-lg mx-auto d-block see-latest" ><p class="button-blue" ><router-link style="color:white" to="/GamesPortofolio"> > &nbsp; See latest</router-link></p></button>
    </div>
    
    <div class="landing_2">
          <!-- <h3>Products</h3> -->
          
        </div>
        <div class="games">
          <div class="container">
            <div class="row">
              <div class="col-md-4" v-for="(product,pIndex) in toBeShown" :key="pIndex">
                <!-- <a :href=product.iframe_url> -->
                <router-link :to="{ name: 'GameDetails', params: { id: product.game_id }}">
                  <img class="mx-auto d-block" :src=product.thumbnail alt="" width="100%" height="200px">
                  <h5><b>{{product.name}} </b></h5>
                </router-link>
                <!-- </a> -->
              </div>
            </div>
          </div>
          
        </div>
        
           <div>
            <!-- <button @click="prevPage" :disabled="currentPage==1">Show Less</button> -->
            <!-- <button class=" btn-lg mx-auto d-block see-more" @click="nextPage" :disabled="currentPage == totalPages"> > See More</button> -->
            <button class=" btn-lg mx-auto d-block see-more" ><p class="button-blue" ><router-link style="color:white" to="/GamesPortofolio"> > &nbsp; See more</router-link></p></button>

          </div>
    <div style="position:relative">
      <img class="landing_3" src="../assets/banner2_main.png">
      <button class=" btn-lg mx-auto d-block see-more-2" ><p class="button-blue" ><router-link style="color:black" to="/GamesPortofolio"> > &nbsp; See more</router-link></p></button>
    </div>
    <div style="position:relative">
      <img class="landing_4" src="../assets/banner3_main.png">
      <button class=" btn-lg mx-auto d-block see-more-3" ><p class="button-blue" ><router-link style="color:black" to="/Contact"> > &nbsp; See more</router-link></p></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
  data () {
    return {
      products: [] ,
      currentPage: 1
    }
    
  },
  mounted(){
      this.products = []
      axios
      .get('https://flipluck.com/api/v1/games')
      .then(response => {
        this.products = response.data;
        console.log(this.products[0])
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })

  },
   computed:{
  	toBeShown() {
    	return this.products.slice(0, this.currentPage * 6);
    },
    totalPages() {
    	return Math.ceil( this.products.length / 6);
    }
  },
  methods: {
  	nextPage(){
    	if(this.currentPage <  this.totalPages) this.currentPage++;
    },
    prevPage(){
    	this.currentPage = this.currentPage - 1 || 1;
    }
  }
}
</script>

<style scoped>
.landing_3{
  width: 100%;
}
.landing_4{
  width: 100%;
}
.conntainer {
  text-align: center;
}
h5 {
  padding-top: 2rem;
  text-align: center;
  color: #000;
}
.row{
  margin-top: 1rem;
}
.see-more{
  background-color: #0A6FF1;
  border-color: #0A6FF1;
  border: none;
  color: white;
  border-radius: 30px;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-size: 25px;
}
.see-latest{
  background-color: #0A6FF1;
  border-color: #0A6FF1;
  border: none;
  color: white;
  border-radius: 30px;
  margin-bottom: 2rem;
  /* margin-top: -9rem; */
  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}

.see-more-2{
  background-color: white;
  border-color: white;
  border: none;
  color: black;
  border-radius: 30px;
  margin-bottom: 2rem;
  /* margin-top: -9rem; */
  font-size: 25px;
  position: absolute;
  left: 20%;
  top: 80%;
  transform: translate(-50%, -50%);
}

.see-more-3{
  background-color: white;
  border-color: white;
  border: none;
  color: black;
  border-radius: 30px;
  margin-bottom: 2rem;
  /* margin-top: -9rem; */
  font-size: 25px;
  position: absolute;
  left: 70%;
  top: 80%;
  transform: translate(-50%, -50%);
}



.button-blue{
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-size: 22px;
}


.col-md-4 {
  padding-bottom: 2rem;
  text-align: center;
}
@media only screen and (min-width: 998px) {
  .landing_1{
    width: 100%;
    margin-top: -4rem;
  }

  .games {
  padding-left: 11rem ;
  padding-right: 11rem ;
  }
}
@media only screen and (max-width: 998px) {
.landing_1{
  width: 100%;
  margin-top: 0rem;
}

}
@media only screen and (max-width: 800px) {


}

a {
  text-decoration: none !important;
}

h5{
  font-size: 16px;
}
.game-image{
  margin-bottom: 1rem;
}

</style>