<template>
  <div id="app">
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"

export default ({
  components:{
    Navbar,
    Footer,
  }
})
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Quicksand');
.v-easy-dialog--backdrop-btn{
  display: none !important;
}
body {
   max-width: 100%;
   overflow-x: hidden;
}
/* body{
  background-image: url("./assets/images/fabrica_grivita.jpg");
  background-color: black;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */
body{
  background-color: white !important;

}




#app {
  font-family: Quicksand;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  color: #2c3e50;
}


</style>
