<template>
  <mdb-navbar expand="large" dark color="black">
    <mdb-navbar-brand href="#">
      <router-link to="/"><img class="logo-img-hidden" src="../assets/logo.png" width="100px"></router-link> 
    </mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav right>
        <li class="nav-link navbar-link"><router-link to="/">HOME</router-link></li>
        <li class="nav-link navbar-link"><router-link to="/GamesPortofolio">GAMES</router-link></li>
        <li class="nav-link navbar-link"><router-link to="/About">ABOUT</router-link></li>
        <li class="nav-link navbar-link"><router-link to="/Contact">CONTACT</router-link></li>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>
<script>
  import { mdbNavbar, mdbNavbarBrand, mdbNavbarToggler, mdbNavbarNav } from 'mdbvue';
  export default {
    name: 'HelloWorld',
    components: {
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
    
    }
  }
</script>
<style scoped>
.black {
  background-color: black !important;
}
.nav-link>a{
  color: white !important;
}
.navbar-nav {
  text-align: right;
}
.navbar{
  z-index: 1;
}
@media only screen and (max-width: 998px) {
  .li-home{
    display: block;
  }
}
@media only screen and (min-width: 998px) {
  .li-home{
    display: none;
  }
  .navbar {
    margin-left: 11rem;
    margin-right: 11rem;
  }
}
.is-replling {
  display: none;
}
</style>