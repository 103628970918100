<template>
<footer>
  <div class="container">
    <div class="row ">
      <div class="col-md-3">
        <h5>
          <a>TERMS OF SERVICE</a>
        </h5>
      </div>
      <div class="col-md-3">
        <h5>
          <a>PRIVACY POLICY</a>
        </h5>
      </div>
      <div class="col-md-3">
        <h5>
          <a>PARENT'S GUIDE</a>
        </h5>
      </div>
      <div class="col-md-3">
        <div class="social">
          <div class="social-div"><a style="color:white" href=""><BIconFacebook/></a></div>
          <div class="social-div"><a style="color:white" href=""><BIconInstagram/></a></div>
          <div class="social-div"><a style="color:white" href=""><BIconTwitter/></a></div>
        </div>
      </div>
    </div>
      
    <br>
    <hr>
    <div class="row">
      <div class="col-md-2">
        <router-link to="/"><img class="logo_footer" src="../assets/logo2.png" ></router-link> 
      </div>
      <div class="col-md-2 copyrights">
        <p>©️ 2021 FlipLuck.<br> All Rights Reserved</p>
      </div>
      <div class="col-md-5" ></div>
      <div class="col-md-2 contact-info" >
        Marbella, Spain <br>sales@flipluck.com<br>+4 0745 129 206
      </div>
    </div>
    
    
  </div>
</footer>
</template>
<script>
import { BIconFacebook , BIconInstagram , BIconTwitter} from 'bootstrap-vue'


export default {
  
    components:{
        BIconFacebook ,
        BIconInstagram,
        BIconTwitter
    }
  }

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

footer{
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: black;
  color: white;
}
hr {
  background-color: white;
}




@media only screen and (max-width: 998px) {
  svg {
    width: auto;
    height: 40px;
  }
  footer {
    text-align: center;
  }
  .social-div {
    display: inline; 
    margin-left: 3rem;
  }
  .social-div:first-of-type{
    margin-left: 0rem;
  }
  .social{
    margin-top: 1rem;
  }
  .col-md-2:first-of-type{
    width: 50%;
    text-align: right;
  }
  .copyrights{
    width: 50%;
    margin-top: 1rem;
    text-align: left;
  }
  .contact-info {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .logo_footer{
    width:100px
  }
}
@media only screen and (min-width: 998px) {
 svg {
    width: auto;
    height: 26px;
  }
  .col-md-3 {
    text-align: left;
  }
  .social-div {
    display: inline; 
    margin-left: 2rem;
  }
  .copyrights{
    margin-top: 2rem;
  }
  .contact-info {
    margin-top: 2rem;
    margin-left: 2rem;
    /* float: right !important; */
  }
  .logo_footer{
    width:120px
  }
}
</style>